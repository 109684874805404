const videoContainer = document.querySelector('.video-container');
const video = videoContainer?.querySelector('.broadcast');

const videoHandler = ({ target }) => {
  if (target instanceof HTMLMediaElement) {
    if ('paused' in target.dataset) {
      target.play();
      delete target.dataset.paused;
    } else {
      target.pause();
      target.dataset.paused = '';
    }
  }
};

if (video) {
  video.addEventListener('click', videoHandler);
}
